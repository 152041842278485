import { SocialAuthService } from '@abacritt/angularx-social-login';
import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '@app/core/services/login.service';
import { TranslationService } from '@app/core/services/translation.service';
import { UserService } from '@app/core/services/user.service';
import { UserAPI } from '@app/lib/api/user/api.user.model';
import { AuthenticationActions } from '@app/modules/main/states/account-settings/authentication/authentication.actions';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { removeCookie } from 'typescript-cookie';

@Component({                                  
  selector: 'otp-twofa-form',
  templateUrl: './otp-twofa-form.component.html',
  styleUrls: ['./otp-twofa-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OtpTwofaFormComponent implements OnInit {
  @Output() verifyCodeEvent = new EventEmitter();
  @Output() changeMoveToVerification = new EventEmitter();
  errorMessage = '';
  isLoading = false;
  otpForm: FormGroup;
  translateResult: any;
  translateCommonResult: any;
  otpDigits: number[] = [1, 2, 3, 4, 5, 6];

  constructor(
    private readonly route: Router,
    private store: Store,
    private fb: FormBuilder,
    private readonly userService: UserService,
    public readonly loginService: LoginService,
    private translateService: TranslationService,
    private socAuthenService: SocialAuthService
  ) {}

  get isFullCode(): boolean {
    return this.otpForm.valid;
  }

  ngOnInit(): void {
    this.createOtpForm();
    this.translateResult = this.translateService.getTranslation('SIGNIN_SIGNUP');
    this.translateCommonResult = this.translateService.getTranslation('COMMON');
  }

  createOtpForm(): void {
    const formControls: any = {};
    for (let digit of this.otpDigits) {
      formControls[`digit${digit}`] = ['', Validators.required];
    }
    this.otpForm = this.fb.group(formControls);
  }

  onInputChange(currentInput: number): void {
    const inputElement = document.getElementById(`verification-2fa-code-input-${currentInput}`) as HTMLInputElement;
    if (inputElement) {
      inputElement.addEventListener('keydown', event => {
        if (event.key === 'Delete' || event.key === 'Backspace') {
          inputElement.value = '';
          this.otpForm.get(`digit${currentInput}`)?.setValue('');
          const previousInput = document.getElementById(`verification-2fa-code-input-${currentInput - 1}`);
          if (previousInput) {
            previousInput.focus();
          }
          event.preventDefault();
        } else {
          const nextInput = document.getElementById(`verification-2fa-code-input-${currentInput + 1}`);
          if (this.otpForm.get(`digit${currentInput}`)?.value && nextInput) {
            nextInput.focus();
          }
        }
      });
      inputElement.focus();
    }
    const result = Object.values(this.otpForm.value).join('');
    if (result.length === this.otpDigits.length) this.onVerifyCode();
  }

  shouldDisableInputCode(currentInput: number): boolean {
    if (currentInput === 1) {
      return !!this.otpForm.get('digit1')?.value;
    }
    return !this.otpForm.get(`digit${currentInput - 1}`)?.value || this.otpForm.get(`digit${currentInput}`)?.value;
  }

  onVerifyCode(): void {
    const result = Object.values(this.otpForm.value).join('');

    this.isLoading = true;
    this.disableAllInput();
    this.verifyCodeEvent.emit(result);
  }

  disableAllInput() {
    this.otpDigits.forEach(digit => {
      this.otpForm.get(`digit${digit}`)?.disable();
    });
  }

  enableAllInput() {
    this.otpDigits.forEach(digit => {
      this.otpForm.get(`digit${digit}`)?.enable();
    });
  }

  handleSignOut(): void {
    localStorage.clear();
    removeCookie(environment.USER_PROFILE);
    removeCookie(environment.TOKEN_KEY);
    this.socAuthenService.signOut();
    sessionStorage.clear();
    this.store.dispatch(AuthenticationActions.onLogout());
    this.changeMoveToVerification.emit(false);
    this.route.navigateByUrl('/login');
  }
}
