export const USER_STATUS = {
  SCHEDULED_DELETED: 'SCHEDULED_DELETED',
  DELETED: 'DELETED',
  INACTIVE: 'INACTIVE',
  BLOCKED: 'BLOCKED',
};

export const AUTH_METHOD = {
  phone: 'phone',
  email: 'email',
};

export const OTP_EXPIRATION_TIME = 180;
export const CHARACTER_TRANSLATION_LIMIT = 5000;
export const TOAST_MESSAGE_LIFETIME_DURATIONS = {
  short: 2000,
  medium: 5000,
  long: 7000
};

export const TOAST_MESSAGE_SEVERITY_LEVELS = {
  warn: 'warn',
  info: 'info',
  success: 'success',
  error: 'error'
};

export const OTP_REQUIRED_STATUS = 69004;
export const INCORRECT_INFO_STATUS = 69001;
export const PERMISSION_DENIED_STATUS = 60002;