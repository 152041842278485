import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthAPI } from '@app/lib/api/auth/api.auth.model';
import { environment } from '@env/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { removeCookie } from 'typescript-cookie';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService,
    private router : Router) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem(environment.TOKEN_KEY);
    if (token)
      request = request.clone({
        setHeaders: {
          authorization: `Bearer ${(JSON.parse(token) as AuthAPI.Token).accessToken}`
        }
      });
    return next.handle(request).pipe(
      tap(() => {}),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          localStorage.clear();
          removeCookie(environment.USER_PROFILE);
          removeCookie(environment.TOKEN_KEY);
          this.router.navigate(['/login'])
        }
        return throwError(error);
      }),
      finalize(() => {})
    );
  }
}
