import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@app/core/services/auth.service';
import { LoginService } from '@app/core/services/login.service';
import { TranslationService } from '@app/core/services/translation.service';
import { UserService } from '@app/core/services/user.service';
import { AuthAPI } from '@app/lib/api/auth/api.auth.model';
import {
  SendCode,
  VerifyCode,
} from '@app/modules/sign-up/models/sign-up.model';
import { OTP_EXPIRATION_TIME } from '@app/shared/constant';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'otp-form',
  templateUrl: './otp-form.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./otp-form.component.scss'],
})
export class OtpFormComponent implements OnInit {
  @Input() isSignUpAccount = true;
  @Output() verifyCodeEvent = new EventEmitter();

  isLoading = false;
  otpForm: FormGroup;
  errorMessage = '';
  countdown = OTP_EXPIRATION_TIME;
  translateResult: any;
  translateCommonResult: any;
  otpDigits: number[] = [1, 2, 3, 4, 5, 6];
  deviceInfo: AuthAPI.DeviceInfo;
  defaultErrorMessage = {
    incorrectOTPCode: 'The code is incorrect.',
  };

  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
    private readonly userService: UserService,
    public readonly loginService: LoginService,
    private translateService: TranslationService,
    private readonly authService: AuthService,
  ) {}

  get isFullCode(): boolean {
    return this.otpForm.valid;
  }

  ngOnInit(): void {
    this.createOtpForm();
    this.translateResult =
      this.translateService.getTranslation('SIGNIN_SIGNUP');
    this.translateCommonResult = this.translateService.getTranslation('COMMON');
    this.authService.getDeviceInfo().subscribe((data) => {
      this.deviceInfo = {
        ...data,
        device_type: 'W',
      };
    });
  }

  createOtpForm(): void {
    const formControls: any = {};
    for (let digit of this.otpDigits) {
      formControls[`digit${digit}`] = ['', Validators.required];
    }
    this.otpForm = this.fb.group(formControls);
    this.startCountDown();
  }

  startCountDown(): void {
    const duration = OTP_EXPIRATION_TIME;
    this.loginService.startCountDown(duration).subscribe((value) => {
      this.countdown = value;
    });
  }

  onInputChange(currentInput: number): void {
    const inputElement = document.getElementById(
      `verification-code-input-${currentInput}`,
    ) as HTMLInputElement;
    if (inputElement) {
      inputElement.addEventListener('keydown', (event) => {
        if (event.key === 'Delete' || event.key === 'Backspace') {
          inputElement.value = '';
          this.otpForm.get(`digit${currentInput}`)?.setValue('');
          const previousInput = document.getElementById(
            `verification-code-input-${currentInput - 1}`,
          );
          if (previousInput) {
            previousInput.focus();
          }
          event.preventDefault();
        } else {
          const nextInput = document.getElementById(
            `verification-code-input-${currentInput + 1}`,
          );
          if (this.otpForm.get(`digit${currentInput}`)?.value && nextInput) {
            nextInput.focus();
          }
        }
      });
      inputElement.focus();
    }
  }

  onResendCode(): void {
    this.startCountDown();
    const sendCodedData: SendCode = {
      phone_or_email: this.userService.authData.phone_or_email,
      is_check_phone_or_email: this.isSignUpAccount,
      area_code: this.userService.authData.area_code,
    };
    this.userService.sendCode(sendCodedData).subscribe(
      (response) => {
        this.errorMessage = response.success ? '' : response.error.message;
        if (response.success) {
          this.messageService.add({
            severity: 'success',
            summary: this.translateCommonResult.SUCCESS,
            detail: response.data,
          });
        } else {
          this.messageService.add({
            severity: 'error',
            summary: this.translateCommonResult.ERROR,
            detail: this.translateResult.FORGOT_PASSWORD.SEND_CODE_SUCCESSFULLY,
          });
        }
      },
      (error) => {
        this.errorMessage = error.error.message;
      },
    );
  }

  shouldDisableInputCode(currentInput: number): boolean {
    if (currentInput === 1) {
      return !!this.otpForm.get('digit1')?.value;
    }
    return (
      !this.otpForm.get(`digit${currentInput - 1}`)?.value ||
      this.otpForm.get(`digit${currentInput}`)?.value
    );
  }

  onVerifyCode(): void {
    this.isLoading = true;
    const result = Object.values(this.otpForm.value).join('');
    const verifyCode: VerifyCode = {
      phone_or_email: this.userService.authData.phone_or_email,
      area_code: this.userService.authData.area_code,
      get_code_verification: result,
      ...this.deviceInfo,
    };
    this.userService.verifyCode(verifyCode).subscribe(
      (response) => {
        if (response.success) {
          this.errorMessage = '';
          this.userService.isNotVerified = false;
          this.userService.authData.token = response.data.token;
          this.verifyCodeEvent.emit(response);
          this.isLoading = false;
        } else {
          const firstInputElement = document.getElementById(
            'verification-code-input-1',
          );
          this.errorMessage = response.error.message;
          this.otpDigits.forEach((index) =>
            this.otpForm.get(`digit${index}`)?.setValue(''),
          );
          firstInputElement?.focus();
          this.verifyCodeEvent.emit(response);
        }
        this.isLoading = false;
      },
      (error) => {
        if (error.hasOwnProperty('error')) {
          this.errorMessage = error.error.message;
        }
        this.createOtpForm();
        this.isLoading = false;
      },
    );
  }
}
