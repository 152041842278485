import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  show: any;
  isEmptyLayout: boolean;
  isInitTranslate = false;
}
