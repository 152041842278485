import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  NavigationExtras,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { environment } from '@env/environment';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';
import { removeCookie } from 'typescript-cookie';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean {
    const isLoggedIn = JSON.parse(localStorage.getItem('auth_status') ?? '[]')?.isLoggedIn || false;
    const token = localStorage.getItem(environment.TOKEN_KEY);
    const isLoginRoute = state.url.startsWith('/login');
    const returnUrl = this.authService.getReturnUrl();
    const navigationExtras: NavigationExtras = { queryParams: { r: returnUrl } };
    if (token && isLoggedIn) {
      this.userService.getModeratorRole().subscribe((res) => {
        if (!res?.length) {
          localStorage.clear();
          removeCookie(environment.TOKEN_KEY)
          removeCookie(environment.USER_PROFILE)
          this.router.navigate(['/login'], returnUrl ? navigationExtras : {});
          return false;
        }  
        return true;
      });
    }

    if (isLoginRoute) {
      if (!isLoggedIn) {
        return true;
      } 
      if (isLoggedIn) {
        this.router.navigate(['']);
        return false;
      }
    }

    if (!isLoggedIn) {
      this.router.navigate(['/login'], returnUrl ? navigationExtras : {});
      return false;
    }

    return true;
  }
}
