import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('@modules/main/main.module').then(m => m.MainModule)
  },
  {
    path: 'login',
    canActivate: [AuthGuard],
    loadChildren: () => import('@modules/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'app-connect',
    loadChildren: () =>
      import('@modules/app-connect/app-connect.module').then(
        (m) => m.AppConnectModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
