<div class="otp-form">
  <div
    class="rounded-lg border border-solid px-3 py-3.5 w-full border-system-error-300 mt-4 mb-4 flex gap-2 items-center"
    *ngIf="errorMessage">
    <i class="sctr-icon-alert-circle text-system-error-700 font-semibold text-sm"></i>
    <span class="font-semibold text-sm text-system-error-700">{{ errorMessage | translate }}</span>
  </div>
  <div class="flex justify-between mb-3 text-sm w-full items-center">
    <span class="font-medium text-gray-700">{{ 'SIGNIN_SIGNUP.SIGNUP.VERIFICATION_CODE' | translate }}</span>
  </div>
  <form [formGroup]="otpForm" (ngSubmit)="onVerifyCode()">
    <div class="verification-2fa-form">
      <input
        *ngFor="let digit of otpDigits; let i = index"
        pInputText
        type="text"
        class="verification-2fa-input"
        [id]="'verification-2fa-code-input-' + (i + 1)"
        [formControlName]="'digit' + (i + 1)"
        [readonly]="shouldDisableInputCode(i + 1)"
        maxlength="1"
        placeholder="-"
        [appOtpPaste]="otpForm"
        (input)="onInputChange(i + 1)" />
    </div>
    <p-button
      type="submit"
      label="{{ 'COMMON.BUTTON_ACTION.CONFIRM' | translate }}"
      styleClass="send-code-btn"
      class="w-full mt-3 hidden"
      [disabled]="!isFullCode || isLoading"></p-button>
  </form>

  <span class="flex justify-center font-normal gap-1 text-sm items-center mt-6"
    >{{ 'SIGNIN_SIGNUP.SIGNUP.BACK_TO' | translate }}
    <span
      class="no-underline font-semibold text-branding-primary-700 text-base cursor-pointer"
      (click)="handleSignOut()"
      >{{ 'SIGNIN_SIGNUP.SIGN_IN' | translate }}</span
    ></span
  >
</div>
