import { Component, OnInit } from '@angular/core';
import { LoginService } from '@app/core/services/login.service';
import { environment } from '@env/environment';

@Component({
  selector: 'authen-container',
  templateUrl: './authen-container.component.html',
  styleUrls: ['./authen-container.component.scss']
})
export class AuthenContainerComponent implements OnInit {
  constructor(private readonly loginService: LoginService) {}
  imageUrl: any;
  appName = environment.APP_NAME;

  ngOnInit(): void {
    this.changeImage();
    setInterval(() => this.changeImage(), 5000);
  }

  changeImage(): void {
    this.imageUrl = this.loginService.getImageAndText();
  }
}
