import { ToastModule } from 'primeng/toast';
import { CheckboxModule } from 'primeng/checkbox';
import { InputNumberModule } from 'primeng/inputnumber';
import { TooltipModule } from 'primeng/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthenContainerComponent } from '@app/modules/login/component/authen-container/authen-container.component';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { OtpFormComponent } from './otp-form/otp-form.component';
import { OtpTwofaFormComponent } from './otp-twofa-form/otp-twofa-form.component';
import { OtpPasteDirective } from './directives/otp-paste.directive';
import { CountdownFormatPipe } from './pipes/countdown-format.pipe';
import { DialogModule } from 'primeng/dialog';
import { SkeletonModule } from 'primeng/skeleton';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { LoadingComponent } from './components/loading/loading.component';
import { BlockUIModule } from 'primeng/blockui';

const sharedComponents = [
  AuthenContainerComponent,
  OtpFormComponent,
  OtpTwofaFormComponent,
  LoadingComponent
];

const sharedDirectives = [OtpPasteDirective];

const sharedPipes = [CountdownFormatPipe];

const commonModules = [
  CommonModule,
  ReactiveFormsModule,
  FormsModule,
  TranslateModule
];
const primeNgModules = [
  InputTextModule,
  ButtonModule,
  InputNumberModule,
  CheckboxModule,
  TooltipModule,
  ToastModule,
  ProgressSpinnerModule,
  DialogModule,
  SkeletonModule,
  BlockUIModule
];

@NgModule({
  declarations: [sharedComponents, sharedDirectives, sharedPipes],
  imports: [commonModules, primeNgModules],
  providers: [ConfirmationService],
  exports: [commonModules, primeNgModules, sharedComponents],
})
export class SharedModule {}
